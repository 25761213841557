.container {
    @media screen and (min-width: 768px) {
        width: 100%;
        max-width: 1630px;
    }
}

.container__wrap {
    padding-left: 0;
    padding-top: 90px;
    min-height: 100vh;
    transition: padding-left 0.3s;

    @media screen and (min-width: 576px) {
        padding-left: 50px;
    }
    @media screen and (min-width: 800px) {
        padding-left: 250px !important;
    }
}
.backdrop {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    &.show {
        opacity: 10;
    }
}
.layout {
    &.layout--collapse {
        & + .container__wrap {
            padding-left: 0;

            @media screen and (min-width: 576px) {
                padding-left: 60px;
            }
        }
    }

    &.layout--top-navigation {
        & + .container__wrap {
            @media screen and (min-width: 576px) {
                padding-left: 0;
            }
        }
    }
}

.load__icon-wrapper {
    position: absolute;
    z-index: 100;
    width: 100vw;
    height: 100%;

    & img {
        width: 100px;
        height: 100px;
        padding-bottom: 15px;
    }
}

.load__icon-wrapper-cl {
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 50%;
}

.load__icon-wb {
    position: absolute;
    top: 50%;
    left: 48%;
    transform: translateX(-50%);
    z-index: 1001;
}

.isLoading {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
    top: 0;
    left: 0;
}

.load__warning {
    color: #fff;
    font-size: 1rem;
    transform: translate(-50%, 30px);
}

.loader-hook {
    z-index: 999;
}
