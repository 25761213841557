.MuiSelect-select {
    background: #f8f8f8 !important;
}

.select-options {
    margin: 0 4px;
    border-radius: 3px;
    cursor: pointer;
    background: transparent;

    &:focus {
        outline: none;
    }

    option {
        background-color: #fff;
    }
}

.react-select {
    width: 100%;
    font-size: 16px;
    border-radius: 5px;
    color: $primary-text !important;
}

.selectHasError {
    .form__form-group-file {
        .file__placeholder {
            border-color: $color-error !important;
        }
    }
    .react-select__control {
        border: solid 1.5px $color-error !important;
    }
}

.react-select__control {
    height: 38px;
    transition: all 0.3s;
    font-size: 1rem;
    border: solid 1.5px $color-border !important;
    background-color: #fff !important;

    &.react-select__control--is-focused,
    &:hover {
        border-color: $primary-color !important;
        box-shadow: none;
        background: transparent;
    }
}

.react-select__input {
    height: 20px;
    //padding-top: 8px;
}

.react-select__indicator-separator {
    display: none;
}

.react-select__dropdown-indicator,
.react-select__clear-indicator {
    cursor: pointer;

    svg {
        height: 16px;
        width: 16px;
    }
}

.react-select__multi-value {
    background-color: transparent;
    border: 1px solid $primary-color;

    .react-select__multi-value__label {
        padding: 3px 6px;
    }
}

.react-select__multi-value__remove {
    opacity: 0.8;
    transition: 0.3s;
    cursor: pointer;
}

.react-select__menu {
    box-shadow: none !important;
    margin-top: 6px;
    margin-bottom: 6px;
    z-index: 111111111111;
    background-color: #f6f6f6 !important;
    border: 1px solid $primary-color;
}

.react-select__menu-list {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    font-size: 1rem;
    animation: open 0.3s ease-in-out;
    overflow: hidden;
}

@keyframes open {
    0% {
        max-height: 0;
    }
    100% {
        max-height: 200px;
    }
}

.react-select__option {
    transition: all 0.3s;
    border-radius: 0;
    display: flex;
    cursor: pointer;
    padding: 8px 10px;

    &:hover {
        background-color: $primary-color;
    }
}

.react-select__option--is-focused {
    background-color: $primary-color !important;
    color: #fff !important;
}

.react-select__option--is-selected {
    background-color: $primary-color !important;
    color: #fff !important;
}

.react-select__color {
    display: block;
    border-radius: 50%;
    height: 10px;
    width: 10px;
    margin-top: auto;
    margin-bottom: auto;
}

.form__select-color {
    display: flex;
    flex-wrap: wrap;
    max-width: 84px;
}

.react-select__indicators {
    padding-top: 0px;
    height: 34px;
    background: #fff;
    & svg {
        fill: $color-border;
        width: 22px;
        height: 22px;
    }
}

.css-1cy84qz-placeholder {
    top: 8px !important;
    left: 0 !important;
}

.css-1g3gda9-placeholder {
    font-weight: 400;
}

.react-select__control--is-disabled {
    background-color: #f6f6f6 !important;
}
.css-b8ldur-Input {
    padding-top: 0 !important;
}

.select-field {
    border: none;
    padding: 0.5rem;
    transition: all 0.2s ease;
    &:active,
    &:hover {
        outline: none;
        border: none;
    }
    option {
        border: none;
    }
}
