.MuiCardHeader-root span {
    font-weight: bold !important;
}
.dashboard__action-button {
    &,
    a,
    .MuiButtonBase-root {
        width: 100%;
    }
    a {
        display: block;
    }
}
