.auth__screen-container {
    background-image: linear-gradient(-25deg, steelblue 0%, rgb(24, 64, 173) 50%, #787985 50%, #d8dfe9 100%);
    height: 100vh;
    // background-image: radial-gradient(circle at right bottom, steelblue 0%, rgb(24, 64, 173) 50%, #b9babe 50%, #d8dfe9 100%);
    // z-index: -1;
    // background-position: 100% 30%;
    // background-size: 150vw 150vw;
    // background-repeat: no-repeat;

    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    height: auto;
}

.auth__brand-container {
    position: relative;
    background-color: whitesmoke;
    height: 70vh;

    align-self: center;
    border-radius: 15px;
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
    @media (max-width: 767px) {
        min-height: 80vh;
    }
    @media (max-width: 576px) {
        min-height: 100vh;
        height: auto;
        border-radius: 0px;
    }
}
.leftSide {
    border-radius: 15px;
    height: 70vh;
    //background-color: aquamarine;
    //border: 1px solid black;
    position: relative;
    overflow: hidden;
    @media (max-width: 767px) {
        display: none;
    }
}
.circle_upper {
    // z-index: 1;
    top: -300px;
    right: 0px;
    width: 1000px;
    height: 1000px;
    position: absolute;
    border-radius: 50%;
    background-color: rgb(29, 15, 110);
    // background-image: url('https://images.unsplash.com/photo-1635357176750-8b251dc0db7f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1632&q=80');
    background-position: center;
    opacity: 0.3;
}
.circle {
    top: -300px;
    right: 0px;
    width: 1000px;
    height: 1000px;
    position: absolute;
    border-radius: 50%;
    background-color: steelblue;
    //background: url('./login.jpg');
    background-position: center;
    opacity: 0.8;
    overflow: hidden;
}
.circle img {
    position: relative;
    height: 100%;
    width: auto;
    top: 0;
    right: 0;
}
.rightSide {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: flex-start;
    border-radius: 15px;
    //border: 1px solid black;
    height: 60vh;
    padding: 1% 5%;
    //background-color: bisque;
    @media (max-width: 767px) {
        height: 80vh;
        padding: 1% 5%;
    }

    @media (max-width: 576px) {
        min-height: 100vh;
        height: auto;
    }

    img {
        @media (max-width: 767px) {
            display: block;
            align-items: center;
            justify-content: center;
            padding: 20px;
        }
        @media (max-width: 576px) {
            padding: 20px;
        }
    }
}
.login_container {
    // display: grid;
    //grid-template-columns: 1fr;
    // flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: hidden;
    padding: 1px;
}

// .auth__form-container {
//     height:100vh;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     // align-items: center;

//     @media(max-width:768px){
//         margin-top: 45px;
//         height: auto;
//     }

//     & h2{
//         @media(max-width:768px){
//             font-size: 1.5rem;
//         }
//     }
// }

// .auth__screen-container {
//     height: 100vh;
//     overflow: hidden;
//     max-height: 100vh;;
//     padding-bottom: 0px;
//     @media(max-width:768px){
//         overflow: visible;
//         max-height: auto;
//         padding-bottom: 20px;

//     }
// }

// .auth__brand-container {
//     background: $primary-color;
//     display:flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: flex-start;

//     & img {
//         //width: 200px;
//         padding: 0 50px;
//         opacity: 1;
//         @media(max-width:768px){
//             padding: 0;
//             width:125px;
//             opacity: 1;
//             margin-left: 5%;
//         }
//     }

//     & h1{

//         @media(max-width:768px){
//             font-size: 1.5rem;
//         }
//     }

//     @media(max-width:768px){
//         padding: 20px 0;
//     }
// }

// .auth__forgot-password {
//     display: flex;
//     justify-content: flex-end;
//     & a{
//     color: $primary-color !important;
//     }
// }

// .auth__button-container {
//     display: flex;
//     justify-content: center;
// }

// .auth__btn {
//     padding: 10px 25px;
//     background: transparent;
//     color: $primary-color;
//     border: solid 2px $primary-color;
//     font-weight:bold;
//     min-width:175px;

//     &:hover{
//         background-color: $primary-color;
//         color: #fff;
//     }

//     &:focus{
//         background-color: $primary-color;
//         outline: none;
//         border: solid 2px $primary-color !important;
//         box-shadow: none;
//     }
// }

// .auth__language-selector {
//     width: 80px;
//     height: 30px;
//     position: absolute;
//     top: 10px;
//     right: 5%;

//     &:focus{
//         outline: none;
//     }

//     @media (min-width:768px){
//         top: 30px;
//         right: 20px;
//         width: 80px;
//         height: 48px;
//     }
// }

// .login__code-expiry{
//     margin-right: 7px;
//     font-weight: bold;
//     color: $primary-color;
// }

.screen-policies {
    position: absolute;
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    bottom: 0px;
    right: 0;
    // margin-top: auto;
    // flex-wrap: nowrap !important;
    height: 70px;
    //min-height: 100px;
    // max-height: 300px;
    justify-content: center;
    align-items: center;
    background-color: #0008;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    @media (max-width: 767px) {
        position: relative;
        display: block;
        // align-items: center;
        // justify-content: center;
        padding: 20px;
        background-color: transparent;
    }
    @media (max-width: 576px) {
        padding: 5px;
        margin-top: 30px;
    }
    div {
        margin-top: 5px;
        margin-bottom: 5px;
        @media (max-width: 767px) {
            margin-top: 15px !important;
        }
    }
    a {
        text-decoration: none;
        z-index: 2;
        padding: 5px 15px;
        margin-top: 5px;
        margin-bottom: 5px;
        // border: 1px solid #787985;
        background-color: #ddd;
        border-radius: 3px;
        &:hover {
            background-color: #ad0109;
            color: white !important;
        }
        @media (max-width: 767px) {
            margin-top: 30px !important;
        }
    }

    // .col-md-4 {
    //     padding: 0;
    //     a{
    //         padding: auto
    //     }
    // }
    // @media (min-width:1025px){
    //     height: 300px;
    // }
}
.login_btn {
    display: block !important;
    @media (max-width: 768px) {
        margin: 0 auto !important;
    }
}

// #brand__tooltip {
//     user-select: none;
// }
