.btn__primary {
    background-color: $primary-color !important;
    border-color: transparent !important;
    position: relative;
    overflow: hidden;
    color: rgb(225, 225, 225) !important;
    transition: background-color 0.5s ease, color 0.5s ease !important;
    // min-width: 155px;
    padding: 3px 15px;

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
        // text-decoration: none;
        outline: none;
        background-color: lighten($primary-color, 10) !important;
        box-shadow: none !important;
    }
    // & a{
    //     text-decoration: none;
    //     color: #000 !important;
    // }
    // &:hover a {
    //   color: $primary-color !important;
    // }
}
.btn__secondary {
    color: rgb(225, 225, 225) !important;
    background-color: $primary-text !important;
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
        // text-decoration: none;
        outline: none;
        background-color: lighten($primary-text, 20) !important;
        box-shadow: none !important;
    }
}

.btn__alternate {
    background-color: $alternate-bg !important;
    // border-color: transparent !important;
    color: $primary-color;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease, color 0.5s ease !important;
    min-width: 155px;
    padding: 15px 25px;
    &:hover {
        color: $alternate-bg;
        background-color: $primary-color !important;
    }
}

.drawertoggle__button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 38px;
    width: 38px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 5px;
    box-sizing: border-box;

    &:focus {
        outline: none;
    }
    @media (min-width: 769px) {
        display: none;
    }
}

.toggle-button__line {
    width: 25px;
    height: 2px;
    background: $primary-text;
}
